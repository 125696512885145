import { useState } from "react";

import {
    KanshiLog
} from "../ui-components";

import {
    KanshiFrameLogDetailsWrapper,
} from "."

import {
    updateMoniteringLogKakuninStatus
} from "../lib/LogUtil";

import { isLocalMode } from "../lib/envUtil";

import { CustomModal } from "../customComponents/CustomModal";

export default function KanshiLogWrapper(props) {
    const [kakuninBtnSituation, setKakuninBtnSituation] = useState("Default");

    function onMouseEnterKakuninBtn() {
        if (props.kakuninStatus === "false") {
            setKakuninBtnSituation("Hover");
        }
    }

    function onMouseLeaveKakuninBtn() {
        setKakuninBtnSituation("Default");
    }

    function onClickKakuninBtn(item_id) {
        if (props.kakuninStatus === "false") {
            updateMoniteringLogKakuninStatus(item_id);
            setKakuninBtnSituation("Default");
        }
    }

    // ログ詳細モーダル表示
    const [confirmLogModal, setConfirmLogModal] = useState(false);

    return (
        <>
            <KanshiLog
                width={"100%"}
                type={props.type}
                logType={props.logtype}
                iconType={props.icontype}
                overrides={{
                    CheckboxField491717853: {
                        ...props.type === "Title" && {
                            isDisabled: props.allCheckDisabled,
                            checked: props.allCheck,
                            onChange: (e) => {
                                console.log("CheckboxField491717853 onChange");
                                e.stopPropagation();
                                let check = e.target.checked;

                                props.onClickAllCheck(check);
                            }
                        },
                        onClick: (e) => {
                            e.stopPropagation();
                            console.log("CheckboxField491717853 onClick");
                        },
                    },
                    Spacer491717854: {
                        // children: "AAA", タイトル行　日付
                    },
                    text491717857: {
                        // children: "BBB", タイトル行　拠点名
                    },  
                    text491717859: {
                        children: "発生元",  // タイトル行　発生元
                    },
                    text491717861: {
                        // children: "DDD", タイトル行　ログ種別
                        width: "145px",
                    },
                    text491717865: {
                        // children: "EEE", タイトル行　確認状態
                        display: !isLocalMode ? "flex" : "none",
                    },
                    Contents: {
                        onClick: (e) => {
                            e.stopPropagation();
                            const tagName = e.target.tagName;

                            if (props.icontype === "CheckOn" && tagName !== "SPAN" && tagName !== "svg"  ) {
                                if (props.checkDisabled) return;

                                props.onClickCheck();
                                return;
                            }
                            
                            if (props.icontype !== "Circle") return;

                            // ログ詳細モーダル表示
                            setConfirmLogModal(true);
                        }
                    },
                    CheckboxField491717868: {
                        checked: props.check,
                        display: props.icontype === "Circle" ? "none" : "flex",
                        isDisabled: props.checkDisabled,
                        onClick: (e) => {
                            e.stopPropagation();
                        },
                        onChange: (e) => {
                            if (props.checkDisabled) return;
                            e.stopPropagation();
                            console.log(e.target.tagName);    

                            let check = e.target.checked;
                            console.log("CheckboxField491717868 onChange", check);

                            props.onClickCheck(check);
                        }
                    },
                    text491717870: {
                        children: props.date,
                    },
                    text491717872: {
                        children: props.kyotenName,
                    },
                    text491717874: {
                        children: props.hasseiMoto,
                    },
                    text491717876: {
                        children: props.logTypeText,
                        width: "145px",
                        whiteSpace: "nowrap",
                    },
                    "Kanshi/Btn/kakunin": {
                        display: !isLocalMode ? "flex" : "none",
                        situation: kakuninBtnSituation,
                        check: props.kakuninStatus,
                        // 確認ボタンは、Gray(info)の場合は、デフォルトに変換
                        type: props.type === "Gray" ? "Default" : props.type,
                        onMouseEnter: onMouseEnterKakuninBtn,
                        onMouseLeave: onMouseLeaveKakuninBtn,
                        onClick: (e) => {
                            e.stopPropagation();

                            onClickKakuninBtn(props.itemId)
                        },
                        style: {
                            cursor: props.kakuninStatus === "false" ? "pointer" : "default",
                        }
                    },
                    "Common/Divider": {},
                }}
            >
            </KanshiLog>

            {/* ログ詳細モーダル */}
            <CustomModal
                open={confirmLogModal}
                onClose={() => setConfirmLogModal(false)}
            >
                <KanshiFrameLogDetailsWrapper
                    log={props.log}
                    date={props.date}
                    kyotenName={props.kyotenName}
                    hasseiMoto={props.hasseiMoto}
                    logType={props.logTypeText}
                    confirmed={props.type}
                    closeModal={() => setConfirmLogModal(false)}
                />
            </CustomModal>
        </>
    );
}