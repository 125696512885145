import React, { useState, useEffect } from "react";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import { CustomModal } from "../customComponents/CustomModal";

import { getFormatNowDate } from '../lib/StorageUtil';
import {
    SetteiItem,
    // SetteiInputBox,
    Spacer,
    CommonDivider
} from '../ui-components';

import {
    SetteiInputBoxWrapper,
    SetteiItemWrapper,
    CheckWrapper,
} from '../componentsWrapper';

import {
    machineKanshiSettingJson,
    getDeviceMoniteringSettingJson,
    convertStatusTypeValueToSelectValue,
    convertSettingValueToInputValue,
    SETTING_INPUTBOX_UNIT_NOTHING,
    convertSelectValueToStatusTypeValue,
    mergeObjects,
    updateDeviceMoniteringSettingJson,
    inputNumberLimit,
    convertInputValueToSettingValue,
} from '../lib/SettingUtil';

import {
    DeviceLogNames, waveMoniteringBandThresholdList
} from '../lib/ConstList';


export default function KanshiSetteiMachineWrapper(props) {
    const [settingValueJson, setSettingValueJson] = useState(null);

    // 変更後の設定値
    const [changeSettingValueJson, setChangeSettingValueJson] = useState(null);

    const getSettingValueJson = async (locationName) => {
        const result = await getDeviceMoniteringSettingJson(locationName);
        console.warn("getSettingValueJson", result);
        setSettingValueJson(result);
        setChangeSettingValueJson(result);
    }

    // UIコンポーネントの都合上、GUNMAが来るのでSAYADOに変換する関数
    const convertLocationName = (locationName) => {
        return locationName === "GUNMA" ? "SAYADO" : locationName;
    }

    useEffect(() => {
        const searchLocationName = props.location === "GUNMA" ? "SAYADO" : props.location;
        getSettingValueJson(searchLocationName);
    }, [props.location]);

    useEffect(() => {
        const searchLocationName = convertLocationName(props.location)
        getSettingValueJson(searchLocationName)
    }, [props.location]);


    // ダウンロードボタンイベント
    useEffect(() => {
        console.log(props.downloadCount);

        if (props.downloadCount > 0) {
            // jsonファイルをダウンロードする
            const json = JSON.stringify(changeSettingValueJson, null, '  ');

            const blob = new Blob([json], { type: "application/json" });

            const a = document.createElement("a");

            a.href = URL.createObjectURL(blob);

            a.download = "interference-monitoring-機器監視設定-" + props.displaySettingJson.location_name[convertLocationName(props.location)] + "-" + getFormatNowDate() + ".json";

            a.click();
        }
    }, [props.downloadCount]);

    useEffect(() => {
        console.log(props.inputSettingJson);

        if (props.inputSettingJson !== null && changeSettingValueJson !== null) {
            // マージする
            const mergeSettingJson = mergeObjects(changeSettingValueJson, props.inputSettingJson);

            // バリデーション
            Object.entries(machineKanshiSettingJson).map((item, index) => {
                Object.entries(item[1]).map((itemDetail, index) => {
                    mergeSettingJson[item[0]][itemDetail[0]].Monitoring = mergeSettingJson[item[0]][itemDetail[0]].Monitoring == true ? true : false;

                    if (!(mergeSettingJson[item[0]][itemDetail[0]].Status_Type == "Error"
                        || mergeSettingJson[item[0]][itemDetail[0]].Status_Type == "Warning")) {
                        mergeSettingJson[item[0]][itemDetail[0]].Status_Type = "Info";
                    }

                    if (itemDetail[1].option1 != null) {
                        console.log(mergeSettingJson[item[0]][itemDetail[0]].Value);
                        console.log(itemDetail[1].option1.input.step);

                        if (mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option1?.settingValueKey] !== undefined) {
                            mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option1.settingValueKey] = convertInputValueToSettingValue(itemDetail[1].option1.unit, inputNumberLimit(
                                convertSettingValueToInputValue(itemDetail[1].option1.unit, mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option1.settingValueKey]),
                                itemDetail[1].option1.input.step, itemDetail[1].option1.range))
                        }
                    }

                    if (itemDetail[1].option2 != null) {
                        if (mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option2?.settingValueKey] !== undefined) {
                            mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option2.settingValueKey] = inputNumberLimit(
                                mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option2.settingValueKey],
                                itemDetail[1].option2.input.step, itemDetail[1].option2.range)
                        }
                    }

                    if (itemDetail[1].option3 != null) {
                        if (mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option3?.settingValueKey] !== undefined) {
                            mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option3.settingValueKey] = inputNumberLimit(
                                mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option3.settingValueKey],
                                itemDetail[1].option3.input.step, itemDetail[1].option3.range)
                        }
                    }

                    if (itemDetail[1].option4 != null) {
                        if (mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option4?.settingValueKey] !== undefined) {
                            mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option4.settingValueKey] = inputNumberLimit(
                                mergeSettingJson[item[0]][itemDetail[0]][itemDetail[1].option4.settingValueKey],
                                itemDetail[1].option4.input.step, itemDetail[1].option4.range)
                        }
                    }

                });
            });

            // マージした設定値をセットする
            setChangeSettingValueJson((prev) => {
                return {
                    ...mergeSettingJson
                }
            });
        }

    }, [props.inputSettingJson]);


    // 設定値を保存するボタンイベント
    const onClickSaveButton = async () => {
        console.log("onClickSaveButton", changeSettingValueJson);

        props.onCloseChangeModal();

        let changeSettingValueJsonCopy = changeSettingValueJson;

        // All設定を追加
        changeSettingValueJsonCopy.All = changeSettingValueJson.Measurement_Pc_Main;

        // 設定更新
        await updateDeviceMoniteringSettingJson(convertLocationName(props.location), changeSettingValueJson);

        // 設定値を再取得
        getSettingValueJson(convertLocationName(props.location))
    }

    return (
        <>
            <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 40px 0px 40px"
                backgroundColor="rgba(255,255,255,1)"
            //   {...getOverrideProps(overrides, "Frame 898")}
            >
                <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    // height="unset"
                    height='calc(100vh - 340px)'
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    overflow="scroll"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Settei505156987")}
                >
                    <Flex
                        gap="48px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="16px 16px 16px 16px"
                        backgroundColor="rgba(245,245,245,1)"
                    // {...getOverrideProps(overrides, "Title")}
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="400"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="152px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="対象機器"
                        // {...getOverrideProps(overrides, "text505156989")}
                        ></Text>
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="400"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="160px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="監視項目"
                        // {...getOverrideProps(overrides, "text505156990")}
                        ></Text>
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="400"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="120px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="有効／無効"
                        // {...getOverrideProps(overrides, "text505156991")}
                        ></Text>
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="400"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="120px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="状態種別"
                        // {...getOverrideProps(overrides, "text505156992")}
                        ></Text>
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="400"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="120px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="監視条件"
                        // {...getOverrideProps(overrides, "text505156993")}
                        ></Text>
                    </Flex>
                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        //   height="576px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 887")}
                    >
                        {changeSettingValueJson !== null &&
                            Object.entries(machineKanshiSettingJson).map((item, index) => (
                                <>
                                    <Flex
                                        gap="0"
                                        direction="row"
                                        width="unset"
                                        height="unset"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        shrink="0"
                                        alignSelf="stretch"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                    // {...getOverrideProps(overrides, "01")}
                                    >
                                        <Flex
                                            gap="10px"
                                            direction="row"
                                            width="192px"
                                            height="unset"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            shrink="0"
                                            alignSelf="stretch"
                                            position="relative"
                                            padding="0px 16px 0px 16px"
                                            backgroundColor="rgba(255,255,255,1)"
                                        // {...getOverrideProps(overrides, "Taisyo505156996")}
                                        >
                                            <Text
                                                fontFamily="Noto Sans"
                                                fontSize="14px"
                                                fontWeight="700"
                                                color="rgba(53,56,59,1)"
                                                lineHeight="25.19999885559082px"
                                                textAlign="left"
                                                display="block"
                                                direction="column"
                                                justifyContent="unset"
                                                letterSpacing="0.55px"
                                                width="160px"
                                                height="unset"
                                                gap="unset"
                                                alignItems="unset"
                                                shrink="0"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                                whiteSpace="pre-wrap"
                                                children={DeviceLogNames[item[0]]}
                                            // {...getOverrideProps(overrides, "text505156997")}
                                            ></Text>
                                        </Flex>
                                        <Flex
                                            gap="0"
                                            direction="column"
                                            width="unset"
                                            height="unset"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            shrink="0"
                                            position="relative"
                                            padding="0px 0px 0px 0px"
                                        // {...getOverrideProps(overrides, "Frame 880505156951")}
                                        >
                                            {Object.entries(item[1]).map((itemDetail, index) =>{
                                                // 表示する拠点を制限
                                                if ("ignoreLocation" in itemDetail[1]) {
                                                    if (itemDetail[1].ignoreLocation.includes(convertLocationName((props.location)))) {
                                                        return <></>;
                                                    }
                                                }

                                                if (item[0] == null || itemDetail[0] == null || changeSettingValueJson[item[0]][itemDetail[0]] == null) {
                                                    return <></>;
                                                }

                                                if (!('Monitoring' in changeSettingValueJson[item[0]][itemDetail[0]])) {
                                                    return <></>;
                                                }

                                                return (
                                                <>
                                                    <SetteiItemWrapper
                                                        key={index}
                                                        setteiname={itemDetail[1].labeltext}
                                                        isvalidoptions={itemDetail[1].isvalidoptions}
                                                        isvalidvalue={changeSettingValueJson[item[0]][itemDetail[0]].Monitoring ? "ON" : "OFF"}
                                                        isvalidvalueOnChange={(e) => {
                                                            console.log(e.target.value);

                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        Monitoring: e.target.value === "ON" ? true : false
                                                                    }
                                                                }
                                                            });
                                                        }}

                                                        statustypeoptions={itemDetail[1].statustypeoptions}
                                                        statustypevalue={convertStatusTypeValueToSelectValue(changeSettingValueJson[item[0]][itemDetail[0]].Status_Type)}
                                                        statustypevalueOnChange={(e) => {
                                                            console.log(e.target.value);
                                                            console.log(convertSelectValueToStatusTypeValue(e.target.value));

                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        Status_Type: convertSelectValueToStatusTypeValue(e.target.value)
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        display1={itemDetail[1].option1 ? "flex" : "none"}
                                                        // texttop1={"21px"}
                                                        label1={itemDetail[1].option1?.labeltext ? "true" : "false"}
                                                        labelValue1={itemDetail[1].option1?.labeltext ? itemDetail[1].option1.labeltext : ""}
                                                        attention1={itemDetail[1].option1?.range ? "true" : "false"}
                                                        attentiontext1={itemDetail[1].option1?.range ? "範囲：" + itemDetail[1].option1.range.min + "-" + itemDetail[1].option1.range.max : ""}
                                                        unit1={itemDetail[1].option1?.unit ? itemDetail[1].option1.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                        inputValue1={changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option1?.settingValueKey] !== undefined ?
                                                            convertSettingValueToInputValue(itemDetail[1].option1.unit, changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option1.settingValueKey]) : ""}
                                                        inputType1={itemDetail[1].option1?.input.type}
                                                        {
                                                        ...itemDetail[1].option1?.input.type == "number" && {
                                                            inputStep1: itemDetail[1].option1.input.step,
                                                            ...itemDetail[1].option1.range !== null && {
                                                                inputMax1: itemDetail[1].option1.range.max,
                                                                inputMin1: itemDetail[1].option1.range.min,
                                                            }
                                                        }
                                                        }
                                                        inputValue1OnChange={(e) => {
                                                            console.log(e.target.value);
                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        [itemDetail[1].option1.settingValueKey]: convertInputValueToSettingValue(itemDetail[1].option1.unit, e.target.value)
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        inputValue1OnBlur={(e) => {
                                                            let value = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0;
                                                            console.log(value);

                                                            // 数字の場合
                                                            if (itemDetail[1].option1.input.type === "number") {
                                                                // 整数の場合
                                                                value = inputNumberLimit(value, itemDetail[1].option1.input.step, itemDetail[1].option1.range)
                                                            }

                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        [itemDetail[1].option1.settingValueKey]: convertInputValueToSettingValue(itemDetail[1].option1.unit, value)
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        display2={itemDetail[1].option2 ? "flex" : "none"}
                                                        // texttop2={"21px"}
                                                        label2={itemDetail[1].option2?.labeltext ? "true" : "false"}
                                                        labelValue2={itemDetail[1].option2?.labeltext ? itemDetail[1].option2.labeltext : ""}
                                                        attention2={itemDetail[1].option2?.range ? "true" : "false"}
                                                        attentiontext2={itemDetail[1].option2?.range ? "範囲：" + itemDetail[1].option2.range.min + "-" + itemDetail[1].option2.range.max : ""}
                                                        unit2={itemDetail[1].option2?.unit ? itemDetail[1].option2.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                        inputValue2={changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option2?.settingValueKey] !== undefined ?
                                                            convertSettingValueToInputValue(itemDetail[1].option2.unit, changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option2.settingValueKey]) : ""}

                                                        inputType2={itemDetail[1].option2?.input.type}
                                                        {
                                                        ...itemDetail[1].option2?.input.type == "number" && {
                                                            inputStep2: itemDetail[1].option2.input.step,
                                                            ...itemDetail[1].option2.range !== null && {
                                                                inputMax2: itemDetail[1].option2.range.max,
                                                                inputMin2: itemDetail[1].option2.range.min,
                                                            }
                                                        }
                                                        }
                                                        inputValue2OnChange={(e) => {
                                                            console.log(e.target.value);
                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        [itemDetail[1].option2.settingValueKey]: e.target.value
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        inputValue2OnBlur={(e) => {
                                                            let value = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0;
                                                            console.log(value);

                                                            // 数字の場合
                                                            if (itemDetail[1].option1.input.type === "number") {
                                                                // 整数の場合
                                                                value = inputNumberLimit(value, itemDetail[1].option2.input.step, itemDetail[1].option2.range)
                                                            }

                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        [itemDetail[1].option2.settingValueKey]: value
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        display3={itemDetail[1].option3 ? "flex" : "none"}
                                                        // texttop3={"21px"}
                                                        label3={itemDetail[1].option3?.labeltext ? "true" : "false"}
                                                        labelValue3={itemDetail[1].option3?.labeltext ? itemDetail[1].option3.labeltext : ""}
                                                        attention3={itemDetail[1].option3?.range ? "true" : "false"}
                                                        attentiontext3={itemDetail[1].option3?.range ? "範囲：" + itemDetail[1].option3.range.min + "-" + itemDetail[1].option3.range.max : ""}
                                                        unit3={itemDetail[1].option3?.unit ? itemDetail[1].option3.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                        inputValue3={changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option3?.settingValueKey] !== undefined ?
                                                            convertSettingValueToInputValue(itemDetail[1].option3.unit, changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option3.settingValueKey]) : ""}

                                                        inputType3={itemDetail[1].option3?.input.type}
                                                        {
                                                        ...itemDetail[1].option3?.input.type == "number" && {
                                                            inputStep3: itemDetail[1].option3.input.step,
                                                            ...itemDetail[1].option3.range !== null && {
                                                                inputMax3: itemDetail[1].option3.range.max,
                                                                inputMin3: itemDetail[1].option3.range.min,
                                                            }
                                                        }
                                                        }
                                                        inputValue3OnChange={(e) => {
                                                            console.log(e.target.value);
                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        [itemDetail[1].option3.settingValueKey]: e.target.value
                                                                    }
                                                                }
                                                            });
                                                        }}

                                                        inputValue3OnBlur={(e) => {
                                                            let value = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0;
                                                            console.log(value);

                                                            // 数字の場合
                                                            if (itemDetail[1].option1.input.type === "number") {
                                                                // 整数の場合
                                                                value = inputNumberLimit(value, itemDetail[1].option3.input.step, itemDetail[1].option3.range)
                                                            }

                                                            setChangeSettingValueJson({
                                                                ...changeSettingValueJson,
                                                                [item[0]]: {
                                                                    ...changeSettingValueJson[item[0]],
                                                                    [itemDetail[0]]: {
                                                                        ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                        [itemDetail[1].option3.settingValueKey]: value
                                                                    }
                                                                }
                                                            });
                                                        }}

                                                        display4={itemDetail[1].option4 ? "flex" : "none"}
                                                        // texttop4={"21px"}
                                                        label4={itemDetail[1].option4?.labeltext ? "true" : "false"}
                                                        labelValue4={itemDetail[1].option4?.labeltext ? itemDetail[1].option4.labeltext : ""}
                                                        attention4={itemDetail[1].option4?.range ? "true" : "false"}
                                                        attentiontext4={itemDetail[1].option4?.range ? "範囲：" + itemDetail[1].option4.range.min + "-" + itemDetail[1].option4.range.max : ""}
                                                        unit4={itemDetail[1].option4?.unit ? itemDetail[1].option4.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                        inputValue4={changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option4?.settingValueKey] !== undefined ?
                                                            convertSettingValueToInputValue(itemDetail[1].option4.unit, changeSettingValueJson[item[0]][itemDetail[0]][itemDetail[1].option4.settingValueKey]) : ""}
                                                        // dispdivider={Object.entries(item[1]).length - 1 !== index ? true : false}
                                                        dispdivider={false}
                                                    // {...getOverrideProps(overrides, "Settei/Item505156952")}
                                                    ></SetteiItemWrapper>
                                                    {Object.entries(item[1]).length - 1 !== index ?
                                                        <CommonDivider
                                                            display="flex"
                                                            gap="0"
                                                            direction="row"
                                                            width="unset"
                                                            height="1px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            overflow="hidden"
                                                            shrink="0"
                                                            alignSelf="stretch"
                                                            position="relative"
                                                            padding="0px 0px 0px 0px"
                                                        // {...getOverrideProps(overrides, "Common/Divider505156999")}
                                                        ></CommonDivider>
                                                        : null}
                                                </>

                                            )})}
                                        </Flex>
                                    </Flex>
                                    <CommonDivider
                                        display="flex"
                                        gap="0"
                                        direction="row"
                                        width="unset"
                                        height="1px"
                                        justifyContent="center"
                                        alignItems="center"
                                        overflow="hidden"
                                        shrink="0"
                                        alignSelf="stretch"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                    // {...getOverrideProps(overrides, "Common/Divider505156999")}
                                    ></CommonDivider>
                                </>
                            ))}
                    </Flex>
                </Flex>
            </Flex>

            {/* 設定変更確認モーダル */}
            <CustomModal
                open={props.openChangeModal}
                onClose={props.onCloseChangeModal}
            >
                <CheckWrapper
                    msg={props.displaySettingJson.location_name[convertLocationName(props.location)] + "の機器監視設定を変更します。よろしいですか？"}
                    onClickOk={onClickSaveButton}
                    onClickCancel={props.onCloseChangeModal}
                />
            </CustomModal>
        </>
    );
}