import { useState, useEffect, useRef } from "react";
import { Flex } from "@aws-amplify/ui-react";
import {
    KanshiLocation,
    // KanshiAlertCard,
} from '../ui-components'
import {
    KanshiAllStateWrapper,
    KanshiKyotenStateWrapper,
} from '../componentsWrapper'

import {
    getDeviceMoniteringStatus,
    getWaveMoniteringStatus,
    subscriveToUpdateDeviceMoniteringStatus,
    subscriveToUpdateWaveMoniteringStatus,
} from '../lib/MoniteringStatusUtil'

import {
    locationKeyList,
    deviceMoniteringDeviceList,
    waveMoniteringBandList,
    waveMoniteringBandThresholdList,
} from "../lib/ConstList";

import { isLocalMode, localLocationName } from "../lib/envUtil";

export default function KanshiLocationWrapper(props) {

    //　拠点タブ(KanshiLocationTab)の状態定義
    const STATE_TAB_ERROR = "Error";
    const STATE_TAB_WARNING = "Warning";
    const STATE_TAB_USUAL = "Usual";

    const STATE_CARD_ERROR = "error";
    const STATE_CARD_WARNING = "warning";
    const STATE_CARD_DEFAULT = "default";

    const TAB_ALL = "全拠点";
    const TAB_YSCC = "YSCC";
    const TAB_SPE = "SPE";
    const TAB_SPW = "SPW";
    const TAB_SAYADO = "SAYADO";

    // 全拠点タブの状態
    const [tabAllSituation, setTabAllSituation] = useState(!isLocalMode ? "Select" : "Default");
    // 各拠点タブの状態
    const [tabYSCCSituation, setTabYSCCSituation] = useState(!isLocalMode || localLocationName !== TAB_YSCC ? "Default" : "Select");
    const [tabSPESituation, setTabSPESituation] = useState(!isLocalMode || localLocationName !== TAB_SPE ? "Default" : "Select");
    const [tabSPWSituation, setTabSPWSituation] = useState(!isLocalMode || localLocationName !== TAB_SPW ? "Default" : "Select");
    const [tabSAYADOSituation, setTabSAYADOSituation] = useState(!isLocalMode || localLocationName !== TAB_SAYADO ? "Default" : "Select");

    // メニューの状態
    const [menuState, setMenuState] = useState("wave");

    // 機器監視ステータスデータの初期化
    const initialDeviceMoniteringStatus = () => {
        const initialData = {};

        locationKeyList.forEach(location => {
            initialData[location] = {};

            deviceMoniteringDeviceList.forEach(device => {
                initialData[location][device] = {
                    state: "Default",
                    location_name: location,
                    device: device
                };
            });
        });

        return initialData;
    }

    // 干渉波監視ステータスデータの初期化
    const initialWaveMoniteringStatus = () => {
        const initialData = {};

        locationKeyList.forEach(location => {
            initialData[location] = {};

            waveMoniteringBandList.forEach(band => {
                const thresholdList = waveMoniteringBandThresholdList[band]
                thresholdList.forEach(threshold => {
                    if (!initialData[location][band]) {
                        initialData[location][band] = {};
                    }
                    initialData[location][band][threshold] = {
                        state: "Default",
                        location_name: location,
                        band: band,
                        threshold: threshold
                    };
                })
            });
        });

        return initialData;
    }

    // 干渉波及び機器ステータスの拠点毎サマリー
    const initialLocationState = () => {
        const initialData = {
            [TAB_ALL]: {
                allStatus: STATE_TAB_USUAL,
                allStatusCount: 0,
                waveState: STATE_CARD_DEFAULT,
                waveStateCount: 0,
                deviceState: STATE_CARD_DEFAULT,
                deviceStateCount: 0,
            },
            [TAB_YSCC]: {
                allStatus: STATE_TAB_USUAL,
                allStatusCount: 0,
                waveState: STATE_CARD_DEFAULT,
                waveStateCount: 0,
                deviceState: STATE_CARD_DEFAULT,
                deviceStateCount: 0,
            },
            [TAB_SPE]: {
                allStatus: STATE_TAB_USUAL,
                allStatusCount: 0,
                waveState: STATE_CARD_DEFAULT,
                waveStateCount: 0,
                deviceState: STATE_CARD_DEFAULT,
                deviceStateCount: 0,
            },
            [TAB_SPW]: {
                allStatus: STATE_TAB_USUAL,
                allStatusCount: 0,
                waveState: STATE_CARD_DEFAULT,
                waveStateCount: 0,
                deviceState: STATE_CARD_DEFAULT,
                deviceStateCount: 0,
            },
            [TAB_SAYADO]: {
                allStatus: STATE_TAB_USUAL,
                allStatusCount: 0,
                waveState: STATE_CARD_DEFAULT,
                waveStateCount: 0,
                deviceState: STATE_CARD_DEFAULT,
                deviceStateCount: 0,
            }
        }

        return initialData
    }

    const [deviceMoniteringStatus, setDeviceMoniteringStatus] = useState(initialDeviceMoniteringStatus());
    const [waveMoniteringStatus, setWaveMoniteringStatus] = useState(initialWaveMoniteringStatus());

    const [locationState, setLocationState] = useState(initialLocationState());

    // 監視ステータスデータのサブスクリプション
    const deviceMoniteringSubscription = useRef(null);
    const waveMoniteringSubscription = useRef(null);

    // 機器監視ステータスデータ取得
    const getDeviceMoniteringStatusData = async () => {
        const result = await getDeviceMoniteringStatus();
        if (Array.isArray(result) && result.length === 0) {
            return;
        }
        const formatData = formatDeviceMoniteringStatus(result);
        // console.log(formatData);
        setDeviceMoniteringStatus(formatData);
    }

    // 干渉波監視ステータスデータ取得
    const getWaveMoniteringStatusData = async () => {
        const result = await getWaveMoniteringStatus();
        if (Array.isArray(result) && result.length === 0) {
            return;
        }
        const formatData = formatWaveMoniteringStatus(result);
        // console.log(formatData);
        setWaveMoniteringStatus(formatData);
    }

    // 機器監視ステータスデータ更新を受け取った際の処理
    const onDeviceMoniteringStatusUpdate = (data) => {
        // console.log("onDeviceMoniteringStatusUpdate", data);

        setDeviceMoniteringStatus(prevStatus => {
            const updatedStatus = { ...prevStatus };
            updatedStatus[data.location_name][data.device] = data;
            return updatedStatus;
        });
    }

    // // 干渉波監視ステータスデータ更新を受け取った際の処理
    const onWaveMoniteringStatusUpdate = (data) => {
        // console.log("onWaveMoniteringStatusUpdate", data);

        setWaveMoniteringStatus(prevStatus => {
            const updatedStatus = { ...prevStatus };
            updatedStatus[data.location_name][data.band][data.threshold_type] = data;
            return updatedStatus;
        });
    }

    const initFetchMoniteringStatusData = async () => {
        // 機器監視ステータスデータと干渉波監視ステータスデータ取得をコール
        await getDeviceMoniteringStatusData();
        await getWaveMoniteringStatusData();

        if (!deviceMoniteringSubscription.current) {
            const observable = await subscriveToUpdateDeviceMoniteringStatus();
            if (observable) {
                // console.log("deviceMoniteringSubscription subscribe");

                deviceMoniteringSubscription.current = observable.subscribe({
                    next: (msg) => {
                        // console.log("onDeviceMoniteringStatusUpdate msg:", msg);
                        const data = msg.value.data.onUpdateDeviceMoniteringStatus;
                        if (data) {
                            onDeviceMoniteringStatusUpdate(data);
                        } else {
                            console.log("onDeviceMoniteringStatusUpdate data is null errors:", msg.value.errors);
                        }
                    },
                    error: (error) => {
                        console.log("onDeviceMoniteringStatusUpdate error:", error);
                    },
                    close: () => {
                        console.log("onDeviceMoniteringStatusUpdate close");
                    }
                });
            }
        } else {
            console.log("KanshiLocationWrapper useEffect deviceMoniteringSubscription already exists");
        }

        if (!waveMoniteringSubscription.current) {
            const observable = await subscriveToUpdateWaveMoniteringStatus();
            if (observable) {
                // console.log("waveMoniteringSubscription subscribe");
                waveMoniteringSubscription.current = observable.subscribe({
                    next: (msg) => {
                        // console.log("onWaveMoniteringStatusUpdate msg:", msg);
                        const data = msg.value.data.onUpdateWaveMoniteringStatus;
                        if (data) {
                            onWaveMoniteringStatusUpdate(data);
                        } else {
                            console.log("onWaveMoniteringStatusUpdate data is null errors:", msg.value.errors);
                        }
                    },
                    error: (error) => {
                        console.log("onWaveMoniteringStatusUpdate error:", error);
                    },
                    close: () => {
                        console.log("onWaveMoniteringStatusUpdate close");
                    }
                });
            }
        } else {
            console.log("KanshiLocationWrapper useEffect waveMoniteringSubscription already exists");
        }
    }


    useEffect(() => {
        // console.log("KanshiLocationWrapper useEffect");

        initFetchMoniteringStatusData();

        return () => {
            // 画面遷移時にサブスクリプションを解除
            if (deviceMoniteringSubscription.current) {
                // console.log("deviceMoniteringSubscription unsubscribe");
                deviceMoniteringSubscription.current.unsubscribe();
                deviceMoniteringSubscription.current = null;
            }

            if (waveMoniteringSubscription.current) {
                // console.log("waveMoniteringSubscription unsubscribe");
                waveMoniteringSubscription.current.unsubscribe();
                waveMoniteringSubscription.current = null;
            }
        }
    }, []);

    // 機器監視ステータスデータか干渉波監視ステータスデータが更新された際に、拠点サマリーを更新
    useEffect(() => {
        const deviceAggregated = aggregateByLocationAndType(deviceMoniteringStatus, 'device');
        const waveAggregated = aggregateByLocationAndType(waveMoniteringStatus, 'wave');

        const mergedData = {};

        for (let location in deviceAggregated) {
            if (!mergedData[location]) {
                mergedData[location] = { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } };
            }
            mergedData[location].device = deviceAggregated[location].device;
        }

        for (let location in waveAggregated) {
            if (!mergedData[location]) {
                mergedData[location] = { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } };
            }
            mergedData[location].wave = waveAggregated[location].wave;
        }

        mergedData[TAB_ALL] = Object.values(mergedData).reduce((acc, curr) => {
            acc.device.errorCount += curr.device.errorCount;
            acc.device.warningCount += curr.device.warningCount;
            acc.wave.errorCount += curr.wave.errorCount;
            acc.wave.warningCount += curr.wave.warningCount;
            return acc;
        }, { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } });

        setLocationState(transformLocationStateData(mergedData));
    }, [deviceMoniteringStatus, waveMoniteringStatus]);

    // 拠点サマリー結果からコンポーネントに受け渡すステータスデータを生成
    function transformLocationStateData(originalData) {
        const newData = {};

        for (let location in originalData) {
            const waveErrorCount = originalData[location].wave.errorCount;
            const deviceErrorCount = originalData[location].device.errorCount;
            const waveWarningCount = originalData[location].wave.warningCount;
            const deviceWarningCount = originalData[location].device.warningCount;

            // 状態判定
            const waveState = waveErrorCount > 0 ? STATE_CARD_ERROR : (waveWarningCount > 0 ? STATE_CARD_WARNING : STATE_CARD_DEFAULT);
            const deviceState = deviceErrorCount > 0 ? STATE_CARD_ERROR : (deviceWarningCount > 0 ? STATE_CARD_WARNING : STATE_CARD_DEFAULT);

            let allState;
            let allStateCount;

            if (waveErrorCount + deviceErrorCount > 0) {
                allState = STATE_TAB_ERROR;
                allStateCount = waveErrorCount + deviceErrorCount; // 両方のエラーカウントの合計
            } else if (waveWarningCount + deviceWarningCount > 0) {
                allState = STATE_TAB_WARNING;
                allStateCount = waveWarningCount + deviceWarningCount; // 両方の警告カウントの合計
            } else {
                allState = STATE_TAB_USUAL;
                allStateCount = 0; // Usualの場合はカウント0
            }

            newData[location] = {
                allStatus: allState,
                allStatusCount: allStateCount,
                waveState: waveState,
                waveStateCount: waveErrorCount + waveWarningCount,
                deviceState: deviceState,
                deviceStateCount: deviceErrorCount + deviceWarningCount
            };
        }

        return newData;
    }

    function aggregateByLocationAndType(data, type) {
        let dataArray = [];

        if (type === 'device') {
            dataArray = Object.values(data).flatMap(locationData => Object.values(locationData));
        } else if (type === 'wave') {
            dataArray = Object.values(data).flatMap(locationData =>
                Object.values(locationData).flatMap(bandData => Object.values(bandData))
            );
        }


        return dataArray.reduce((acc, curr) => {
            if (!acc[curr.location_name]) {
                acc[curr.location_name] = { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } };
            }

            if (curr.state === "Error") {
                acc[curr.location_name][type].errorCount += 1;
            } else if (curr.state === "Warning") {
                acc[curr.location_name][type].warningCount += 1;
            }

            return acc;
        }, {});
    };

    // 機器監視ステータスデータをlocation_nameとdeviceでインジケータ表示単位にグルーピング
    function formatDeviceMoniteringStatus(deviceMoniteringStatusResult) {
        const formatDeviceMoniteringStatus = {}

        deviceMoniteringStatusResult.forEach((item) => {
            const { location_name, device } = item;

            if (formatDeviceMoniteringStatus[location_name] === undefined) {
                formatDeviceMoniteringStatus[location_name] = {}
            }

            formatDeviceMoniteringStatus[location_name][device] = item;
        });

        return formatDeviceMoniteringStatus;
    }

    // 干渉波監視ステータスデータをlocation_nameとbandと閾値判定タイプでインジケータ表示単位にグルーピング
    function formatWaveMoniteringStatus(waveMoniteringStatusResult) {
        const formatWaveMoniteringStatus = {}

        waveMoniteringStatusResult.forEach((item) => {
            const { location_name, band, threshold_type } = item;

            if (formatWaveMoniteringStatus[location_name] === undefined) {
                formatWaveMoniteringStatus[location_name] = {}
            }

            if (formatWaveMoniteringStatus[location_name][band] === undefined) {
                formatWaveMoniteringStatus[location_name][band] = {}
            }

            formatWaveMoniteringStatus[location_name][band][threshold_type] = item;
        });

        return formatWaveMoniteringStatus;
    }


    function onMouseEventEnter(tabName) {
        switch (tabName) {
            case TAB_ALL:
                if (tabAllSituation === "Default") {
                    setTabAllSituation("Hover");
                }
                break;
            case TAB_YSCC:
                if (tabYSCCSituation === "Default") {
                    setTabYSCCSituation("Hover");
                }
                break;
            case TAB_SPE:
                if (tabSPESituation === "Default") {
                    setTabSPESituation("Hover");
                }
                break;
            case TAB_SPW:
                if (tabSPWSituation === "Default") {
                    setTabSPWSituation("Hover");
                }
                break;
            case TAB_SAYADO:
                if (tabSAYADOSituation === "Default") {
                    setTabSAYADOSituation("Hover");
                }
                break;
            default:
                break;
        }
    }

    function onMouseEventLeave(tabName) {
        switch (tabName) {
            case TAB_ALL:
                if (tabAllSituation === "Hover") {
                    setTabAllSituation("Default");
                }
                break;
            case TAB_YSCC:
                if (tabYSCCSituation === "Hover") {
                    setTabYSCCSituation("Default");
                }
                break;
            case TAB_SPE:
                if (tabSPESituation === "Hover") {
                    setTabSPESituation("Default");
                }
                break;
            case TAB_SPW:
                if (tabSPWSituation === "Hover") {
                    setTabSPWSituation("Default");
                }
                break;
            case TAB_SAYADO:
                if (tabSAYADOSituation === "Hover") {
                    setTabSAYADOSituation("Default");
                }
                break;
            default:
                break;
        }
    }

    function onClickTab(tabName) {
        props.setSelectedLocation(tabName);
        tabName === TAB_ALL ? setTabAllSituation("Select") : setTabAllSituation("Default");
        tabName === TAB_YSCC ? setTabYSCCSituation("Select") : setTabYSCCSituation("Default");
        tabName === TAB_SPE ? setTabSPESituation("Select") : setTabSPESituation("Default");
        tabName === TAB_SPW ? setTabSPWSituation("Select") : setTabSPWSituation("Default");
        tabName === TAB_SAYADO ? setTabSAYADOSituation("Select") : setTabSAYADOSituation("Default");
    }

    useEffect(() => {
        console.warn("[KanshiLocationWrapper] deviceMoniteringStatus", deviceMoniteringStatus);
    }, [deviceMoniteringStatus]);

    useEffect(() => {
        console.warn("[KanshiLocationWrapper] waveMoniteringStatus", waveMoniteringStatus);
    }, [waveMoniteringStatus]);

    return (
        <>
            <KanshiLocation width={"40%"} children={""}
                overrides={{
                    "Kanshi/Location/Tab36163935": {
                        display: !isLocalMode ? "flex" : "none",
                        onClick: () => onClickTab(TAB_ALL),
                        onMouseEnter: () => onMouseEventEnter(TAB_ALL),
                        onMouseLeave: () => onMouseEventLeave(TAB_ALL),
                        type: locationState[TAB_ALL].allStatus,
                        situation: tabAllSituation,
                        overrides: {
                            text438115330: {
                                children: TAB_ALL,
                            },
                            text438115332: {
                                children: locationState[TAB_ALL].allStatusCount,
                            },
                            NumberBadge: {
                                display: "none",
                            }
                        },
                        style: {
                            cursor: "pointer",
                        },
                    },
                    "Kanshi/Location/Tab36163938": {
                        display: !isLocalMode || localLocationName === TAB_YSCC ? "flex" : "none",
                        onClick: () => onClickTab(TAB_YSCC),
                        onMouseEnter: () => onMouseEventEnter(TAB_YSCC),
                        onMouseLeave: () => onMouseEventLeave(TAB_YSCC),
                        type: locationState[TAB_YSCC].allStatus,
                        situation: tabYSCCSituation,
                        overrides: {
                            text438115330: {
                                children: props.displaySettingJson.location_name[TAB_YSCC],
                            },
                            text438115332: {
                                lineHeight: "16px",
                                children: locationState[TAB_YSCC].allStatusCount,
                            },
                        },
                        style: {
                            cursor: "pointer",
                        },
                    },
                    "Kanshi/Location/Tab36163942": {
                        display: !isLocalMode || localLocationName === TAB_SPE ? "flex" : "none",
                        onClick: () => onClickTab(TAB_SPE),
                        onMouseEnter: () => onMouseEventEnter(TAB_SPE),
                        onMouseLeave: () => onMouseEventLeave(TAB_SPE),
                        type: locationState[TAB_SPE].allStatus,
                        situation: tabSPESituation,
                        overrides: {
                            text438115330: {
                                children: props.displaySettingJson.location_name[TAB_SPE],
                            },
                            text438115332: {
                                lineHeight: "16px",
                                children: locationState[TAB_SPE].allStatusCount,
                            },
                        },
                        style: {
                            cursor: "pointer",
                        },
                    },
                    "Kanshi/Location/Tab36163944": {
                        display: !isLocalMode || localLocationName === TAB_SPW ? "flex" : "none",
                        onClick: () => onClickTab(TAB_SPW),
                        onMouseEnter: () => onMouseEventEnter(TAB_SPW),
                        onMouseLeave: () => onMouseEventLeave(TAB_SPW),
                        type: locationState[TAB_SPW].allStatus,
                        situation: tabSPWSituation,
                        overrides: {
                            text438115330: {
                                children: props.displaySettingJson.location_name[TAB_SPW],
                            },
                            text438115332: {
                                lineHeight: "16px",
                                children: locationState[TAB_SPW].allStatusCount,
                            },
                        },
                        style: {
                            cursor: "pointer",
                        },
                    },
                    "Kanshi/Location/Tab36163946": {
                        display: !isLocalMode || localLocationName === TAB_SAYADO ? "flex" : "none",
                        onClick: () => onClickTab(TAB_SAYADO),
                        onMouseEnter: () => onMouseEventEnter(TAB_SAYADO),
                        onMouseLeave: () => onMouseEventLeave(TAB_SAYADO),
                        type: locationState[TAB_SAYADO].allStatus,
                        situation: tabSAYADOSituation,
                        overrides: {
                            text438115330: {
                                children: props.displaySettingJson.location_name[TAB_SAYADO],
                            },
                            text438115332: {
                                lineHeight: "16px",
                                children: locationState[TAB_SAYADO].allStatusCount,
                            },
                        },
                        style: {
                            cursor: "pointer",
                        },
                    },
                    KanshiLocation: {},
                }}
            // situation={kanshiLocationTab}
            ></KanshiLocation>
            {tabAllSituation === "Select" ?
                <KanshiAllStateWrapper
                    state={locationState}
                    displaySettingJson={props.displaySettingJson}
                    onClickBtnAlert={(location, type) => {
                        setMenuState(type)
                        onClickTab(location)
                    }}
                >
                </KanshiAllStateWrapper>
                :
                <KanshiKyotenStateWrapper
                    displaySettingJson={props.displaySettingJson}
                    location={props.selectedLocation}
                    waveState={locationState[props.selectedLocation].waveState}
                    deviceState={locationState[props.selectedLocation].deviceState}
                    deviceMoniteringStatus={deviceMoniteringStatus[props.selectedLocation]}
                    waveMoniteringStatus={waveMoniteringStatus[props.selectedLocation]}
                    menuState={menuState}
                >
                </KanshiKyotenStateWrapper>
            }
        </>
    );
}